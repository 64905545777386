import { FC, useEffect, useState } from 'react';

import { useAnalyses } from 'src/hooks/analyses';
import { UploadImageFile, LoadingDisplay, AnalysisResult } from 'src/components';

const ImageUploadPage: FC = () => {
	const { analyze, loading } = useAnalyses();

	const [image, setImage] = useState<any>(null);
	const [analysis, setAnalysis] = useState<any>(null);
	// 0 = waiting, 1 = loading, 2 = finished
	const [analysisState, setAnalysisState] = useState<number>(0);
	const [analysisStateComponent, setAnalysisStateComponent] = useState<any>();

	const uploadImageToAnalysis = async () => {
		if (!image) return;

		const formData = new FormData();
		formData.append('image', image, image.name);

		setAnalysisState(1);
		const newAnalysis = await analyze(formData);
		setAnalysis(newAnalysis);
		setAnalysisState(2);
	};

	const resetAnalysisState = () => {
		setImage(null);
		setAnalysisState(0);
	};

	const setComponent = () => {
		switch (analysisState) {
			case 0:
				return setAnalysisStateComponent(
					<UploadImageFile
						onUpload={uploadImageToAnalysis}
						image={image ? URL.createObjectURL(image) : null}
						setImage={setImage}
					/>
				);

			case 1:
				return setAnalysisStateComponent(<LoadingDisplay />);

			case 2:
				return setAnalysisStateComponent(
					<AnalysisResult
						analysis={analysis}
						onRetry={uploadImageToAnalysis}
						onNew={resetAnalysisState}
					/>
				);

			default:
				return setAnalysisStateComponent(<></>);
		}
	};

	useEffect(() => {
		setComponent();
	}, [analysisState, image]);

	return (
		<div className='page flex column'>
			<div className='container' style={{ marginTop: '3%' }}>
				<h1 className='text-18 primary'>Leitura de Calado de Navios</h1>
			</div>
			<div className='container flex center-x' style={{ height: '85%', overflow: 'hidden' }}>
				{analysisStateComponent}
			</div>
		</div>
	);
};

export default ImageUploadPage;

import { FC, useEffect } from 'react';
import { Button } from '@mui/material';
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';

import { useTags } from 'src/hooks/tags';
import { DataTable, InsertButton } from 'src/components';
import { IManagementPage, ITags } from 'src/types';

const tagTemplate: ITags = {
	id: '',
	code: '',
	name: '',
	group: '',
	clientId: '',
};

const TagsManagement: FC<IManagementPage> = (params) => {
	const { entities, fetchEntities, createNewEntity, editEntity, deleteEntity } = useTags();

	useEffect(() => {
		tagTemplate.clientId = params.parentId ?? '';
		fetchEntities('', params.parentId);
	}, []);

	return (
		<div className='container flex column px-2'>
			<div className='flex space-between center'>
				<Button
					variant='text'
					sx={{ minWidth: 'fit-content' }}
					onClick={() => params.setPage('clients')}
				>
					<ArrowBackOutlinedIcon />
				</Button>
				<h4
					style={{
						fontSize: '14pt',
						fontWeight: 500,
					}}
				>
					Tags
				</h4>
				<InsertButton item={entities[0] ?? tagTemplate} service={createNewEntity} />
			</div>
			<DataTable
				entities={entities}
				editEntity={editEntity}
				deleteEntity={deleteEntity}
				setPage={params.setPage}
			/>
		</div>
	);
};

export default TagsManagement;

import { FC, useState } from "react";
import {
  Box,
  Button,
  Checkbox,
  ClickAwayListener,
  Grid,
  Tooltip,
  Typography,
} from "@mui/material";
import { TooltipProps, tooltipClasses } from "@mui/material/Tooltip";
import CalendarIcon from "@mui/icons-material/CalendarMonthOutlined";
import CollectionsIcon from "@mui/icons-material/Collections";
import ScheduleOutlinedIcon from "@mui/icons-material/ScheduleOutlined";
import VideocamOutlinedIcon from "@mui/icons-material/VideocamOutlined";
import ThumbUpOutlinedIcon from "@mui/icons-material/ThumbUpOutlined";
import ThumbDownOutlinedIcon from "@mui/icons-material/ThumbDownOutlined";
import moment from "moment";

import styled from "styled-components";
import theme from "src/components/theme";
import { useAnalyses } from "src/hooks/analyses";
import { IReportItem } from "src/types";
import { useResponsive } from "src/hooks/responsive";

/**
 * This component returns an CRUD Data Grid customized
 * It uses mui styles
 * @component
 */
const ReportItem: FC<IReportItem> = (props: IReportItem) => {
  const analysesHook = useAnalyses();
  const responsiveHook = useResponsive();

  // For mobile layout
  const [observationTooltipShow, setObservationTooltipShow] =
    useState<boolean>(false);

  /**
   * Function that returns an lighted tooltip for observation button
   * @effect
   */
  const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(() => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.common.white,
      color: "rgba(0, 0, 0, 0.87)",
      boxShadow: theme.shadows[1],
      fontSize: 14,
      fontWeight: 400,
      padding: "8px",
    },
  }));

  return (
    <Box
      display="flex"
      flexDirection="column"
      flex={1}
      flexGrow={1}
      alignItems="center"
      width="100%"
      sx={{ backgroundColor: "#F5F5F5", border: "solid 1px #E0E0E0" }}
    >
      <Grid
        container
        display="flex"
        justifyContent="center"
        padding="8px"
        rowSpacing="8px"
      >
        <Grid
          item
          xs={12}
          sm={2}
          md={1}
          lg={1}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <Checkbox
            checked={
              props.selectedAnalysisIds.find(
                (item) => item === props.analysis.id
              ) !== undefined
            }
            onClick={() =>
              props.analysis.id && props.onSelect(props.analysis.id)
            }
          />
        </Grid>
        <Grid
          item
          xs={12}
          sm={2}
          md={1}
          lg={1}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <Typography
            fontSize="13px"
            fontWeight="400"
            lineHeight="24px"
            letterSpacing="0.5px"
            textTransform="initial"
          >
            {props.index +
              1 +
              parseInt(analysesHook.entitiesPerRow) * analysesHook.paginate}
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          sm={2}
          md={2}
          lg={1}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <Typography
            fontSize="13px"
            fontWeight="400"
            lineHeight="24px"
            letterSpacing="0.5px"
            textTransform="initial"
          >
            <span
              style={{
                fontSize: "14px",
                fontWeight: 700,
                color: theme.palette.secondary.main,
              }}
            >
              ID
            </span>{" "}
            {props.analysis.id?.match(/[^-]+/g)?.[1].toUpperCase()}
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          sm={3}
          md={2}
          lg={2}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <CalendarIcon htmlColor={theme.palette.secondary.main} />
          <Typography
            fontSize="13px"
            fontWeight="400"
            lineHeight="24px"
            letterSpacing="0.5px"
            textTransform="initial"
            paddingLeft="4px"
          >
            {moment(props.analysis.startedAt).format("DD.MM.YYYY")}
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          sm={3}
          md={2}
          lg={1}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <ScheduleOutlinedIcon htmlColor={theme.palette.secondary.main} />
          <Typography
            fontSize="13px"
            fontWeight="400"
            lineHeight="24px"
            letterSpacing="0.5px"
            textTransform="initial"
            paddingLeft="4px"
          >
            {moment(props.analysis.startedAt).format("HH:mm:ss")}
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          sm={4}
          md={3}
          lg={2}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <VideocamOutlinedIcon htmlColor={theme.palette.secondary.main} />
          <Typography
            fontSize="13px"
            fontWeight="400"
            lineHeight="24px"
            letterSpacing="0.5px"
            textTransform="initial"
            paddingLeft="4px"
          >
            {props.analysis && props.analysis.images.length > 0
              ? `${props.analysis.images[0].device.name}`
              : "Não identificado"}
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          sm={2}
          md={2}
          lg={1}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <CollectionsIcon htmlColor={theme.palette.secondary.main} />
          <Typography
            fontSize="13px"
            fontWeight="400"
            lineHeight="24px"
            letterSpacing="0.5px"
            textTransform="initial"
            paddingLeft="4px"
          >
            {props.analysis.images.length}
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          sm={2}
          md={2}
          lg={1}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <ThumbUpOutlinedIcon
            style={{ minWidth: "32px", maxWidth: "48px" }}
            htmlColor={
              props.analysis.isAiRight === 1
                ? theme.palette.success.main
                : theme.palette.backgroundElements.dark
            }
          />
          <ThumbDownOutlinedIcon
            style={{ minWidth: "32px", maxWidth: "48px" }}
            htmlColor={
              props.analysis.isAiRight === 0
                ? theme.palette.error.main
                : theme.palette.backgroundElements.dark
            }
          />
        </Grid>
        <Grid
          item
          xs={12}
          sm={4}
          md={8}
          lg={2}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          {responsiveHook.smUp ? (
            <LightTooltip
              title={props.analysis.comments
                .map((item) => item.body)
                .join("\n")}
            >
              <Button
                size="small"
                variant="contained"
                color="secondary"
                disableFocusRipple
                disableTouchRipple
                disableRipple
                disableElevation
              >
                <Typography
                  color="white"
                  fontSize="14px"
                  fontWeight="700"
                  lineHeight="24px"
                  letterSpacing="0.5px"
                  textTransform="initial"
                >
                  Observação
                </Typography>
              </Button>
            </LightTooltip>
          ) : (
            <ClickAwayListener
              onClickAway={() => setObservationTooltipShow(false)}
            >
              <LightTooltip
                PopperProps={{
                  disablePortal: true,
                }}
                open={observationTooltipShow}
                title={props.analysis.comments
                  .map((item) => item.body)
                  .join("\n")}
              >
                <Button
                  size="small"
                  variant="contained"
                  color="secondary"
                  disableFocusRipple
                  disableTouchRipple
                  disableRipple
                  onClick={() =>
                    setObservationTooltipShow(!observationTooltipShow)
                  }
                >
                  <Typography
                    color="white"
                    fontSize="14px"
                    fontWeight="700"
                    lineHeight="24px"
                    letterSpacing="0.5px"
                    textTransform="initial"
                  >
                    Observação
                  </Typography>
                </Button>
              </LightTooltip>
            </ClickAwayListener>
          )}
        </Grid>
      </Grid>
    </Box>
  );
};

export default ReportItem;

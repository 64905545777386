import { EntityServices } from "./entities";
import { IInspections } from "src/types";

export class InspectionsServices extends EntityServices<IInspections> {

  constructor () {
    const _baseUrl: string = "/inspections";
    super("Inspeção", _baseUrl)
  }
}

import { FC, useEffect, useMemo } from 'react';
import { useDropzone } from 'react-dropzone';

import { IUploadImageFile } from 'src/types';
import { Button } from '@mui/material';
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import { theme, ActionButton } from 'src/components';

const baseStyle: any = {
	display: 'flex',
	width: '94%',
	height: '94%',
	justifyContent: 'center',
	alignItems: 'center',
	padding: '3%',
	borderWidth: 2,
	borderRadius: 2,
	borderColor: '#eeeeee',
	borderStyle: 'dashed',
	backgroundColor: '#fafafa',
	color: '#bdbdbd',
	outline: 'none',
	transition: 'border .24s ease-in-out',
	cursor: 'pointer',
};

const UploadImageFile: FC<IUploadImageFile> = (params) => {
	const clearImage = () => {
		params.setImage(null);
	};

	const { acceptedFiles, getRootProps, getInputProps, isFocused, isDragAccept, isDragReject } =
		useDropzone({
			accept: {
				'image/png': ['.png'],
				'image/jpeg': ['.jpg', '.jpeg'],
			},
			maxFiles: 1,
			multiple: false,
		});

	const style = useMemo(
		() => ({
			...baseStyle,
			...(isFocused ? { borderColor: theme.palette.secondary.main } : {}),
			...(isDragAccept ? { borderColor: theme.palette.success.main } : {}),
			...(isDragReject ? { borderColor: theme.palette.error.main } : {}),
		}),
		[isFocused, isDragAccept, isDragReject]
	);

	useEffect(() => {
		if (acceptedFiles.length !== 0) params.setImage(acceptedFiles[0]);
	}, [acceptedFiles]);

	return (
		<div
			className='container flex column center'
			style={{ width: '99.5%', height: '90%', gap: '3%' }}
		>
			{params.image ? (
				<div
					className='container flex center-x'
					style={{
						position: 'relative',
						overflow: 'hidden',
						backgroundColor: '#fafafa',
						borderWidth: 2,
						borderRadius: 2,
						borderColor: theme.palette.secondary.main,
						borderStyle: 'dashed',
					}}
				>
					<img src={params.image} style={{ height: '100%' }} />
					<Button
						variant='text'
						disableRipple
						sx={{
							position: 'absolute',
							top: '2%',
							right: '1%',
							minWidth: '48px',
							maxWidth: '64px',
							minHeight: '48px',
							maxHeight: '64px',
							padding: '0',
						}}
						onClick={clearImage}
					>
						<ClearOutlinedIcon htmlColor={theme.palette.error.main} fontSize='large' />
					</Button>
				</div>
			) : (
				<div {...getRootProps({ style })}>
					<input {...getInputProps()} />

					{!isDragAccept && !isDragReject && (
						<div className='flex column center-y'>
							<FileUploadOutlinedIcon style={{ fontSize: '60pt' }} />
							<p
								style={{
									fontWeight: 500,
									fontSize: '18pt',
									whiteSpace: 'pre-wrap',
									textAlign: 'center',
								}}
							>
								{'Arraste uma imagem\nou clique aqui para carrega-la'}
							</p>
						</div>
					)}
					{isDragAccept && (
						<div className='flex column center-y'>
							<FileUploadOutlinedIcon
								htmlColor={theme.palette.success.main}
								style={{ fontSize: '60pt' }}
							/>
							<p
								style={{
									fontWeight: 700,
									fontSize: '18pt',
									color: theme.palette.success.main,
									whiteSpace: 'pre-wrap',
									textAlign: 'center',
								}}
							>
								{'Solte a imagem para\nenvia-la para leitura'}
							</p>
						</div>
					)}
					{isDragReject && (
						<div className='flex column center-y'>
							<FileUploadOutlinedIcon
								htmlColor={theme.palette.error.main}
								style={{ fontSize: '60pt' }}
							/>
							<p
								style={{
									fontWeight: 700,
									fontSize: '18pt',
									color: theme.palette.error.main,
									whiteSpace: 'pre-wrap',
									textAlign: 'center',
								}}
							>
								{'Apenas uma imagem (jpg ou png)\npor vez é permitida'}
							</p>
						</div>
					)}
				</div>
			)}
			<ActionButton
				text={'Realizar leitura'}
				color={theme.palette.primary.main}
				onClick={params.onUpload}
			/>
		</div>
	);
};

export default UploadImageFile;

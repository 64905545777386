import { FC, createContext, useContext, useState } from "react";
import { InspectionsServices } from "src/services/inspections";
import { IInspections, IInspectionsContext, IHookProvider } from "src/types";

/*
Creates a new context using a given interface as type
*/
const InspectionsContext = createContext<IInspectionsContext>(
  {} as IInspectionsContext
);

/**
Applies state and CRUD functionalities for inspection objects,
providing them to child components
@provider
*/
export const InspectionsProvider: FC<IHookProvider> = (
  _params: IHookProvider
) => {
  const inspectionServices = new InspectionsServices();

  const [entities, setInspections] = useState<IInspections[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [searchEntity, setSearchEntity] = useState<string>("");
  const [entitiesPerRow, setEntitiesPerRow] = useState<string>("8");
  const [paginate, setPaginate] = useState<number>(0);

  const createNewEntity = async (_inspection: IInspections) => {
    try {
      const inspection = await inspectionServices.createEntity(_inspection);

      //   fetchEntities();
      return inspection;
    } catch (_err) {
      throw _err;
    }
  };

  const editEntity = async (_id: string, _inspection: IInspections) => {
    try {
      const inspection = await inspectionServices.updateEntity(
        _id,
        _inspection
      );

      //   fetchEntities();
      return inspection;
    } catch (_err) {
      throw _err;
    }
  };

  const deleteEntity = async (_id: string) => {
    try {
      const inspection = await inspectionServices.deleteEntity(_id);

      //   fetchEntities();
      return inspection;
    } catch (_err) {
      throw _err;
    }
  };

  const fetchEntities = async (_query?: string) => {
    let inspections: IInspections[] = [];
    setLoading(true);
    try {
      inspections = await inspectionServices.getEntities(_query);
      setInspections([...inspections]);
    } catch (_err) {
      console.log(_err);
    } finally {
      setLoading(false);
      return inspections;
    }
  };

  const fetchEntity = async (_id: string) => {
    const inspection: IInspections = await inspectionServices.getEntity(_id);
    return inspection;
  };

  return (
    <InspectionsContext.Provider
      value={{
        entities,
        loading,
        fetchEntities,
        fetchEntity,
        createNewEntity,
        editEntity,
        deleteEntity,

        searchEntity,
        setSearchEntity,
        entitiesPerRow,
        setEntitiesPerRow,
        paginate,
        setPaginate,
      }}
    >
      {_params.children}
    </InspectionsContext.Provider>
  );
};

/*
Calls current context to be used in child component
*/
export function useInspections() {
  const context = useContext(InspectionsContext);

  if (!context) {
    throw new Error(
      "useInspections must be used within an InspectionsProvider"
    );
  }

  return context;
}

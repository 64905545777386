import { FC, createContext, useContext, useState } from "react";
import { OrdersServices } from "src/services/ordersOfService";
import {
  IOrders,
  IOrderImages,
  IOrdersContext,
  IHookProvider,
} from "src/types";

/*
Creates a new context using a given interface as type
*/
const OrdersContext = createContext<IOrdersContext>({} as IOrdersContext);

/**
Applies state and CRUD functionalities for OS objects,
providing them to child components
@provider
*/
export const OrdersProvider: FC<IHookProvider> = (_params: IHookProvider) => {
  const orderServices = new OrdersServices();

  const [entities, setOrders] = useState<IOrders[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [searchEntity, setSearchEntity] = useState<string>("");
  const [entitiesPerRow, setEntitiesPerRow] = useState<string>("8");
  const [paginate, setPaginate] = useState<number>(0);

  const createNewEntity = async (_os: IOrders) => {
    try {
      const os = await orderServices.createEntity(_os);

      fetchEntities();
      return os;
    } catch (_err) {
      throw _err;
    }
  };

  const editEntity = async (_id: string, _os: IOrders) => {
    try {
      const os = await orderServices.updateEntity(_id, _os);

      fetchEntities();
      return os;
    } catch (_err) {
      throw _err;
    }
  };

  const deleteEntity = async (_id: string) => {
    try {
      const os = await orderServices.deleteEntity(_id);

      fetchEntities();
      return os;
    } catch (_err) {
      throw _err;
    }
  };

  const fetchEntities = async (_query?: string) => {
    let orders: IOrders[] = [];
    setLoading(true);
    try {
      orders = await orderServices.getEntities(_query);
      setOrders([...orders]);
    } catch (_err) {
      console.log(_err);
    } finally {
      setLoading(false);
      return orders;
    }
  };

  const fetchEntity = async (_id: string) => {
    const os: IOrders = await orderServices.getEntity(_id);
    return os;
  };

  const fetchImages = async (_query?: string, osId?: string) => {
    let osImages: IOrderImages[] = [];
    try {
      osImages = await orderServices.getImages(_query, osId);
    } catch (_err) {
      console.log(_err);
    } finally {
      return osImages;
    }
  };

  const checkOSProgress = async (_id: string) => {
    return await orderServices.checkProgress(_id);
  };

  return (
    <OrdersContext.Provider
      value={{
        entities,
        loading,
        fetchEntities,
        fetchEntity,
        createNewEntity,
        editEntity,
        deleteEntity,
        fetchImages,
        checkOSProgress,

        searchEntity,
        setSearchEntity,
        entitiesPerRow,
        setEntitiesPerRow,
        paginate,
        setPaginate,
      }}
    >
      {_params.children}
    </OrdersContext.Provider>
  );
};

/*
Calls current context to be used in child component
*/
export function useOS() {
  const context = useContext(OrdersContext);

  if (!context) {
    throw new Error("useOrders must be used within an OrdersProvider");
  }

  return context;
}

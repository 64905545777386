import { FC, useEffect } from 'react';

import { useAnalyses } from 'src/hooks/analyses';
import { ItemList, ItemDetailed, LoadingDisplay } from 'src/components';

/**
Wrapper component to list items and item details components.
@component
*/
const DatalogPage: FC = () => {
	const { fetchAll, createQuery, loading } = useAnalyses();

	const getAnalyses = async () => {
		const query: string = createQuery({
			startDate: null,
			endDate: null,
			startTime: null,
			endTime: null,
			deviceNames: [],
			orderByDesc: true,
		});
		fetchAll(query);
	};

	useEffect(() => {
		getAnalyses();
	}, []);

	return (
		<div>
			{loading ? (
				<div className='container app-page'>
					<LoadingDisplay />
				</div>
			) : (
				<div className='page'>
					<ItemDetailed />
					<ItemList />
				</div>
			)}
		</div>
	);
};

export default DatalogPage;

import { FC, createContext, useContext, useState } from "react";
import { ImagesServices } from "src/services/images";
import {
  IImages,
  IImagesAnalyses,
  IImagesContext,
  IHookProvider,
} from "src/types";

/*
Creates a new context using a given interface as type
*/
const ImagesContext = createContext<IImagesContext>({} as IImagesContext);

/**
Applies state and CRUD functionalities for image objects,
providing them to child components
@provider
*/
export const ImagesProvider: FC<IHookProvider> = (_params: IHookProvider) => {
  const imageServices = new ImagesServices();

  const [entities, setImages] = useState<IImages[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [searchEntity, setSearchEntity] = useState<string>("");
  const [entitiesPerRow, setEntitiesPerRow] = useState<string>("8");
  const [paginate, setPaginate] = useState<number>(0);

  const createNewEntity = async (_image: IImages) => {
    try {
      const image = await imageServices.createEntity(_image);

      fetchEntities();
      return image;
    } catch (_err) {
      throw _err;
    }
  };

  const editEntity = async (_id: string, _image: IImages) => {
    try {
      const image = await imageServices.updateEntity(_id, _image);

      fetchEntities();
      return image;
    } catch (_err) {
      throw _err;
    }
  };

  const deleteEntity = async (_id: string) => {
    try {
      const image = await imageServices.deleteEntity(_id);

      fetchEntities();
      return image;
    } catch (_err) {
      throw _err;
    }
  };

  const fetchEntities = async (_query?: string) => {
    let images: IImages[] = [];
    setLoading(true);
    try {
      images = await imageServices.getEntities(_query);
      setImages([...images]);
    } catch (_err) {
      console.log(_err);
    } finally {
      setLoading(false);
      return images;
    }
  };

  const fetchEntity = async (_id: string) => {
    const image: IImages = await imageServices.getEntity(_id);
    return image;
  };

  const fetchImage = async (_id: string) => {
    const image = await imageServices.getImage(_id);
    return image;
  };

  const fetchImageAnalyses = async (_query?: string, imageId?: string) => {
    let images: IImagesAnalyses[] = [];
    try {
      images = await imageServices.getImagesAnalyses(_query, imageId);
    } catch (_err) {
      console.log(_err);
    } finally {
      return images;
    }
  };

  return (
    <ImagesContext.Provider
      value={{
        entities,
        setImages,
        loading,
        fetchEntities,
        fetchEntity,
        fetchImage,
        createNewEntity,
        editEntity,
        deleteEntity,
        fetchImageAnalyses,

        searchEntity,
        setSearchEntity,
        entitiesPerRow,
        setEntitiesPerRow,
        paginate,
        setPaginate,
      }}
    >
      {_params.children}
    </ImagesContext.Provider>
  );
};

/*
Calls current context to be used in child component
*/
export function useImages() {
  const context = useContext(ImagesContext);

  if (!context) {
    throw new Error("useImages must be used within an ImagesProvider");
  }

  return context;
}

import axios, { AxiosResponse, AxiosError } from "axios";

let appUrl = process.env.PIX_QUALITY_APP_URL ?? "https://web.portosudeste.pixforce.ai";
let apiUrl = process.env.PIX_QUALITY_API_URL ?? "https://backend.portosudeste.pixforce.ai";
let keycloakUrl = process.env.PIX_QUALITY_KEYCLOAK_URL ?? "https://keycloak.naturgy.pixforce.ai";
let keycloakRealm = process.env.PIX_QUALITY_KEYCLOAK_REALM ?? "Naturgy";
let keycloakClientId = process.env.PIX_QUALITY_KEYCLOAK_CLIENT_ID ?? "web-platform";

export const APP_URL = appUrl;
export const API_URL = apiUrl;
export const KEYCLOAK_URL = keycloakUrl;
export const KEYCLOAK_REALM = keycloakRealm;
export const KEYCLOAK_CLIENT_ID = keycloakClientId;

const api = axios.create({
    baseURL: API_URL,
});

api.interceptors.response.use(
    (response: AxiosResponse) => Promise.resolve(response),
    (error: AxiosError) => {
        return Promise.reject(error);
    }
);

export default api;

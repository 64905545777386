// import { FC, useEffect, useState, useCallback } from 'react';
import { FC } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { AppProvider } from 'src/hooks';
// import { useAuth } from 'src/hooks/auth';
import AppHeader from 'src/components/molecules/appHeader';
// import { HomePage, DatalogPage, ReportsPage, ManagementPage, AnalysisPage } from 'src/pages';
// import { HomePage, ManagementPage, AnalysisPage } from 'src/pages';
import { HomePage, ImageUploadPage } from 'src/pages';

const paths = {
	home: '/',
	dashboard: 'dashboard',
	datalog: 'datalog',
	streaming: 'streaming',
	reports: 'reports',
	management: 'management',
	analysis: 'analysis',
	imageUpload: 'imageUpload',
};

/*
Applies web browser router atributes and functionalities to its children

Determines routes available to navigation and which component to call
in specified path
*/
const RouterProvider: FC = () => {
	// const authHooks = useAuth();

	// const [initKC, setInitKC] = useState<boolean>(true);

	// const init = useCallback(() => {
	// 	if (initKC) authHooks.keycloakInit();
	// 	setInitKC(false);
	// }, [initKC, authHooks]);

	// useEffect(() => {
	// 	init();
	// }, [init]);

	return (
		<BrowserRouter>
			<AppProvider>
				<AppHeader />
				<Routes>
					<Route path={paths.home} element={<HomePage />} />
					{/* <Route index path={paths.analysis} element={<AnalysisPage />} /> */}
					<Route index path={paths.imageUpload} element={<ImageUploadPage />} />
					{/* <Route path={paths.datalog} element={<DatalogPage />} /> */}
					{/* <Route path={paths.reports} element={<ReportsPage />} /> */}
					{/* <Route path={paths.management} element={<ManagementPage />} /> */}
				</Routes>
			</AppProvider>
		</BrowserRouter>
	);
};

export default RouterProvider;

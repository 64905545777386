import { FC } from 'react';

import { IAnalysisResult } from 'src/types';
import TimerOutlinedIcon from '@mui/icons-material/TimerOutlined';
import { theme, ImageZoomModalButton, ActionButton, IconTextDisplay } from 'src/components';
import { NO_IMAGE } from 'src/utils/consts';

const AnalysisResult: FC<IAnalysisResult> = (params) => {
	const formatResult = (result: number) => {
		return result.toString().replace('.', ',');
	};

	const formatTime = (time: number) => {
		let minutes: any = Math.floor(time / 60);
		let seconds: any = Math.ceil(time % 60);

		if (minutes + ''.length < 10) {
			minutes = '0' + minutes;
		}

		if (seconds + ''.length < 10) {
			seconds = '0' + seconds;
		}

		return `${minutes}:${seconds}`;
	};

	return (
		<div className='container flex' style={{ height: '90%', gap: '3%' }}>
			<div
				className='flex center'
				style={{
					width: '50%',
					overflow: 'hidden',
					position: 'relative',
					border: `1px solid ${theme.palette.backgroundElements.main}`,
					borderRadius: '3px',
				}}
			>
				{params.analysis.image ? (
					<img
						height='100%'
						src={`data:image/jpeg;base64,${params.analysis.image}`}
						alt='Resultado da leitura de calado de navio'
						onError={(event) => (event.currentTarget.src = NO_IMAGE)}
					/>
				) : (
					<img height='100%' src={NO_IMAGE} alt='Imagem não encontrada' />
				)}

				{params.analysis.image && (
					<ImageZoomModalButton
						image_path={'data:image/png;base64, ' + params.analysis.image}
					/>
				)}
			</div>

			<div className='flex column space-between' style={{ width: '33%' }}>
				<div className='flex column' style={{ height: '50%', gap: '5%' }}>
					<h2 className='text-16 secondary m-0'>Resultado</h2>
					<div
						className='flex center'
						style={{
							width: '100%',
							height: '125px',
							backgroundColor: params.analysis.result
								? '#fafafa'
								: theme.palette.error.main,
							border: `2px solid ${
								params.analysis.result ? theme.palette.tertiary.main : 'transparent'
							}`,
							borderRadius: '5px',
							marginBottom: '2%',
						}}
					>
						{params.analysis.result ? (
							<span
								style={{
									fontSize: '36pt',
									fontWeight: 700,
								}}
							>{`${formatResult(params.analysis.result)} metros`}</span>
						) : (
							<span
								style={{
									fontSize: '36pt',
									fontWeight: 700,
									color: theme.palette.common.white,
								}}
							>
								{'Erro na leitura'}
							</span>
						)}
					</div>
					<IconTextDisplay
						icon={<TimerOutlinedIcon htmlColor={theme.palette.secondary.main} />}
						textContent={
							params.analysis.duration
								? `Processado em ${formatTime(params.analysis.duration)} ${
										params.analysis.duration > 60 ? 'minutos' : 'segundos'
								  }`
								: 'Não foi possível medir a duração da análise'
						}
					/>
				</div>

				<div
					className='flex column'
					style={{ height: '33%', gap: '10%', justifyContent: 'end' }}
				>
					<ActionButton
						text={'Refazer leitura'}
						color={theme.palette.tertiary.main}
						onClick={params.onRetry}
					/>
					<ActionButton
						text={'Realizar nova leitura'}
						color={theme.palette.primary.main}
						onClick={params.onNew}
					/>
				</div>
			</div>
		</div>
	);
};

export default AnalysisResult;

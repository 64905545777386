import { FC } from "react";
import { Button, Chip } from "@mui/material";
import {
  DataGrid,
  GridColDef,
  GridRowModel,
  GridRowHeightParams,
} from "@mui/x-data-grid";
import LocalOfferOutlinedIcon from "@mui/icons-material/LocalOfferOutlined";

import { UpdateButton, DeleteButton } from "src/components";
import { IDataTable } from "src/types";
import { translateData } from "src/utils/translate";
import { parseDateToString, DATE_TIME_FORMAT } from "src/utils/dates";

const fieldsToIgnore = ["id", "username", "enabled", "roles"];

const DataTable: FC<IDataTable> = (params) => {
  const getColumns = (item: any) => {
    const columns: GridColDef[] = [];

    if (item !== null) {
      const fields = Object.keys(item);
      fields.push("order");

      for (let index = 0; index < fields.length; index++) {
        const header = fields[index];
        if (fieldsToIgnore.includes(header) || header.includes("Id")) continue;

        if (header === "order") {
          columns.unshift({
            field: header,
            headerName: "",
            width: 60,
            align: "center",
          });
        } else if (header === "code") {
          columns.push({
            field: header,
            headerName: translateData(header),
            width: 80,
            align: "center",
          });
        } else if (header === "address") {
          columns.push({
            field: header,
            headerName: translateData(header),
            minWidth: 200,
            flex: 2,
          });
        } else if (header === "access" && !fields.includes("email")) {
          columns.push({
            field: header,
            headerName: translateData(header),
            minWidth: 300,
            flex: 4,
            renderCell: (cellParams) => {
              return (
                <div className="my-1">
                  {cellParams.row.access.map((role: any, index: number) => (
                    <Chip
                      key={index}
                      size="small"
                      style={{ margin: "1% 1% 1% 0" }}
                      label={role}
                    />
                  ))}
                </div>
              );
            },
          });
        } else {
          columns.push({
            field: header,
            headerName: translateData(header.replace("Id", "")),
            minWidth: 150,
            flex: 1,
          });
        }
      }

      if (fields.includes("access") && !fields.includes("email")) {
        return columns;
      }

      const isClients = fields.includes("cnpj");

      columns.push({
        field: "actions",
        headerName: "",
        width: isClients ? 140 : 100,
        sortable: false,
        renderCell: (cellParams) => {
          return (
            <div className="container flex space-between">
              {isClients && (
                <Button
                  variant="text"
                  sx={{ minWidth: "fit-content" }}
                  onClick={() => {
                    params.setSelectedId(cellParams.row.id);
                    params.setPage("tags");
                  }}
                >
                  <LocalOfferOutlinedIcon />
                </Button>
              )}
              <UpdateButton
                entityTemplate={params.entityTemplate}
                item={cellParams.row}
                service={params.editEntity}
              />
              <DeleteButton
                item={cellParams.row}
                service={params.deleteEntity}
              />
            </div>
          );
        },
      });
    }

    return columns;
  };

  const getRows = (items: any) => {
    const rows: GridRowModel[] = [];

    for (let index = 0; index < items.length; index++) {
      const tempItem: any = {
        order: "",
        // actions: null,
      };
      Object.assign(tempItem, items[index]);

      tempItem.order = (index + 1).toString();
      tempItem.createdAt = parseDateToString(
        tempItem.createdAt,
        DATE_TIME_FORMAT
      );
      tempItem.updatedAt = parseDateToString(
        tempItem.updatedAt,
        DATE_TIME_FORMAT
      );
      tempItem.createdTimestamp = parseDateToString(
        tempItem.createdTimestamp,
        DATE_TIME_FORMAT
      );
      rows.push(tempItem);
    }

    return rows;
  };

  return (
    <DataGrid
      getRowHeight={() => "auto"}
      rows={getRows(params.entities)}
      columns={getColumns(
        params.entities.length > 0 ? params.entities[0] : null
      )}
      autoPageSize
      pagination
      style={{
        border: "none",
      }}
    />
  );
};

export default DataTable;

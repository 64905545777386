import { FC, useEffect, useState } from 'react';
import { Button } from '@mui/material';

import { useRoles } from 'src/hooks/roles';
import { DataTable, InsertButton } from 'src/components';
import { IManagementPage } from 'src/types';

const RolesManagement: FC<IManagementPage> = (params) => {
	const { entities, fetchEntities, createNewEntity, editEntity, deleteEntity } = useRoles();
	const [entity, setEntity] = useState<any>(entities[0]);

	const formatRoleFields = () => {
		const tempEntity: any = {};
		Object.assign(tempEntity, entity);

		tempEntity.access = entity?.access;
		setEntity(tempEntity);
	};

	useEffect(() => {
		fetchEntities();
	}, []);

	useEffect(() => {
		formatRoleFields();
	}, []);

	return (
		<div className='container flex column px-2'>
			{/* <InsertButton item={entities[0]} service={createNewEntity} /> */}
			<div className='container flex column end py-2'>
				<Button disabled variant='contained' sx={{ minWidth: 'fit-content' }}>
					Adicionar novo
				</Button>
			</div>

			<DataTable
				entityTemplate={entity}
				entities={entities}
				editEntity={editEntity}
				deleteEntity={deleteEntity}
				setPage={params.setPage}
			/>
		</div>
	);
};

export default RolesManagement;

import { FC, useState } from "react";
import { useAnalyses } from "src/hooks/analyses";

import { theme } from "src/components";
import { Button } from "@mui/material";
import ThumbUpOutlinedIcon from "@mui/icons-material/ThumbUpOutlined";
import ThumbDownOutlinedIcon from "@mui/icons-material/ThumbDownOutlined";
import { IConfirmDetectionAction } from "src/types";

const ConfirmDetectionAction: FC<IConfirmDetectionAction> = (
  params: IConfirmDetectionAction
) => {
  const { entities, selectedAnalysisIndex, editEntity } = useAnalyses();

  const [isConfirmed, setIsConfirmed] = useState<number>(
    entities[selectedAnalysisIndex].isAiRight
  );

  const updateConfirmation = async (clickResult: number) => {
    const analysis = entities[selectedAnalysisIndex];
    analysis.isAiRight = clickResult;

    if (analysis.id) {
      await editEntity(analysis.id, analysis);
    }
  };

  const handleSteps = async (clickResult: number) => {
    if (clickResult === 1) {
      setIsConfirmed(isConfirmed === 1 ? 2 : 1);
      if (isConfirmed === 1) {
        setIsConfirmed(2);
        clickResult = 2;
      } else {
        setIsConfirmed(1);
        clickResult = 1;
      }
    } else {
      if (isConfirmed === 0) {
        setIsConfirmed(2);
        clickResult = 2;
      } else {
        setIsConfirmed(0);
        clickResult = 0;
      }
    }

    await updateConfirmation(clickResult);
  };

  return (
    <div className="container flex space-between">
      <Button
        variant="contained"
        sx={{ width: "45%" }}
        color={isConfirmed === 1 ? "success" : "inherit"}
        disableRipple
        disableElevation
        onClick={() => {
          handleSteps(1);
        }}
      >
        <ThumbUpOutlinedIcon
          htmlColor={
            isConfirmed === 1
              ? theme.palette.background.default
              : theme.palette.backgroundElements.dark
          }
        />
      </Button>

      <Button
        variant="contained"
        sx={{ width: "45%" }}
        color={isConfirmed === 0 ? "error" : "inherit"}
        disableRipple
        disableElevation
        onClick={() => {
          handleSteps(0);
        }}
      >
        <ThumbDownOutlinedIcon
          htmlColor={
            isConfirmed === 0
              ? theme.palette.background.default
              : theme.palette.backgroundElements.dark
          }
        />
      </Button>
    </div>
  );
};

export default ConfirmDetectionAction;

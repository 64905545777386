import { FC, FormEvent, useState, useEffect } from 'react';
import { Button, Modal, TextField, Select, SelectChangeEvent, MenuItem } from '@mui/material';

import { theme } from 'src/components';
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';
import { IUpdateDataButton } from 'src/types';
import { translateData } from 'src/utils/translate';

const fieldsToIgnore = [
	'id',
	'order',
	'username',
	'enabled',
	'roles',
	'createdTimestamp',
	'createdAt',
	'updatedAt',
];

const InsertButton: FC<IUpdateDataButton> = (params) => {
	const [itemFields, setItemFields] = useState<any[]>([]);
	const [fieldsToAdd, setFieldsToAdd] = useState<any[]>([]);

	const [invalidCnpj, setInvalidCnpj] = useState(false);
	const [open, setOpen] = useState(false);
	const handleOpen = () => setOpen(true);
	const handleClose = () => {
		setOpen(false);
		setFieldsToAdd([]);
		setItemFields([]);
		setInvalidCnpj(false);
	};

	const getItemFields = (item: any) => {
		const tempFields = [];

		for (const key in item) {
			if (fieldsToIgnore.includes(key) || key.includes('Id')) continue;
			const field = {
				key: key,
				value: item[key],
			};
			tempFields.push(field);
		}

		if (tempFields.find((field) => field.key === 'email')) {
			tempFields.push({
				key: 'password',
				value: '',
			});
		}

		setItemFields(tempFields);
	};

	const requestInsertItem = async (event: FormEvent<HTMLFormElement>, item: any) => {
		event.preventDefault();

		const tempItem: any = {};
		// Object.assign(tempItem, item);
		let newField: any = null;

		for (const key in item) {
			if (fieldsToIgnore.includes(key)) {
				delete tempItem[key];
				newField = null;
			} else {
				newField = fieldsToAdd.find((field) => (field ? field['key'] === key : false));
			}

			if (newField) {
				if (key === 'access') {
					tempItem[key] = [newField['value']];
				} else {
					tempItem[key] = newField['value'];
				}
			}
		}
		const cnpj = fieldsToAdd.find((newField) =>
			newField ? newField['key'] === 'cnpj' : false
		);

		if (
			cnpj &&
			(cnpj['value'].length !== 18 ||
				!/^[0-9]{2}[\.][0-9]{3}[\.][0-9]{3}[\/][0-9]{4}[-][0-9]{2}$/.test(cnpj['value']))
		) {
			setInvalidCnpj(true);
			alert('CNPJ inválido.');
			return;
		}

		const psw = fieldsToAdd.find((newField) =>
			newField ? newField['key'] === 'password' : false
		);
		if (psw) {
			tempItem['password'] = psw['value'];
		}

		const hasAccess = itemFields.find((field) => field['key'] === 'access');
		if (!tempItem?.access && hasAccess) {
			tempItem['access'] = [hasAccess['value'][0]];
		}

		let result = null;
		try {
			result = await params.service(tempItem);
		} catch {
			// mostrar notificação de falha
		}

		if (result) {
			handleClose();
		}
		setFieldsToAdd([]);
	};

	useEffect(() => {
		getItemFields(params.item);
	}, [open]);

	return (
		<div className='container flex column end py-2'>
			<Button variant='contained' sx={{ minWidth: 'fit-content' }} onClick={handleOpen}>
				Adicionar novo
			</Button>

			<Modal open={open} onClose={handleClose}>
				<div
					className='flex column end box-25 p-2'
					style={{
						backgroundColor: theme.palette.background.default,
						position: 'absolute',
						left: '50%',
						top: '50%',
						transform: 'translate(-50%, -50%)',
						boxShadow: '0 0 100px black',
					}}
				>
					<div
						className='container flex center-y space-between'
						style={{
							marginBottom: 10,
						}}
					>
						<h2 className='m-0 primary'>Adicionar novo</h2>
						<Button
							variant='text'
							sx={{ minWidth: 'fit-content', padding: '0' }}
							onClick={handleClose}
						>
							<ClearOutlinedIcon htmlColor={theme.palette.error.main} />
						</Button>
					</div>

					<form
						className='container flex column'
						onSubmit={(event) => requestInsertItem(event, params.item)}
					>
						{itemFields.map((attr: any, index: number) => (
							<div key={index} className='flex center-y'>
								<label style={{ width: '25%' }} htmlFor={index.toString()}>
									{translateData(attr.key)}
								</label>
								{attr.key !== 'access' ? (
									<TextField
										key={index}
										size='small'
										value={fieldsToAdd[index]?.value}
										onChange={(event) => {
											const tempFields = fieldsToAdd;
											tempFields[index] = {
												key: attr.key,
												value: event.target.value,
											};
											setFieldsToAdd(tempFields);
										}}
										type={attr.key !== 'password' ? 'text' : 'password'}
										sx={{
											width: '75%',
											margin: '1% 0% 1% 2%',
										}}
										error={attr.key === 'cnpj' ? invalidCnpj : false}
									/>
								) : (
									<Select
										size='small'
										value={fieldsToAdd[index]?.value}
										defaultValue={attr.value[0]}
										onChange={(event: SelectChangeEvent) => {
											const tempFields = fieldsToAdd;
											tempFields[index] = {
												key: attr.key,
												value: event.target.value,
											};
											setFieldsToAdd(tempFields);
										}}
										sx={{
											width: '75%',
											margin: '1% 0% 1% 2%',
										}}
									>
										{attr.value.map((groupName: any, index: number) => (
											<MenuItem key={index} value={groupName}>
												{groupName}
											</MenuItem>
										))}
									</Select>
								)}
							</div>
						))}
						<div className='container flex column end'>
							<Button
								variant='contained'
								type='submit'
								sx={{
									minWidth: 'fit-content',
									marginTop: '2%',
								}}
							>
								Confirmar
							</Button>
						</div>
					</form>
				</div>
			</Modal>
		</div>
	);
};

export default InsertButton;

import { FC, createContext, useContext, useState } from "react";
import { RolesServices } from "src/services/roles";
import { IRoles, IRolesContext, IHookProvider } from "src/types";

/*
Creates a new context using a given interface as type
*/
const RolesContext = createContext<IRolesContext>({} as IRolesContext);

/**
Applies state and CRUD functionalities for role objects,
providing them to child components
@provider
*/
export const RolesProvider: FC<IHookProvider> = (_params: IHookProvider) => {
  const rolesServices = new RolesServices();

  const [entities, setRoles] = useState<IRoles[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [searchEntity, setSearchEntity] = useState<string>("");
  const [entitiesPerRow, setEntitiesPerRow] = useState<string>("8");
  const [paginate, setPaginate] = useState<number>(0);

  const createNewEntity = async (_role: IRoles) => {
    try {
      const role = await rolesServices.createEntity(_role);

      fetchEntities();
      return role;
    } catch (_err) {
      throw _err;
    }
  };

  const editEntity = async (_id: string, _role: IRoles) => {
    try {
      const role = await rolesServices.updateEntity(_id, _role);

      fetchEntities();
      return role;
    } catch (_err) {
      throw _err;
    }
  };

  const deleteEntity = async (_id: string) => {
    try {
      const role = await rolesServices.deleteEntity(_id);

      fetchEntities();
      return role;
    } catch (_err) {
      throw _err;
    }
  };

  const fetchEntities = async (_query?: string) => {
    let roles: IRoles[] = [];
    setLoading(true);
    try {
      roles = await rolesServices.getEntities(_query);
      setRoles([...roles]);
    } catch (_err) {
      console.log(_err);
    } finally {
      setLoading(false);
      return roles;
    }
  };

  const fetchGroups = async (_query?: string) => {
    let groups: string[] = [];
    try {
      groups = await rolesServices.getGroups(_query);
    } catch (_err) {
      console.log(_err);
    } finally {
      return groups;
    }
  };

  const fetchEntity = async (_id: string) => {
    const role: IRoles = await rolesServices.getEntity(_id);
    return role;
  };

  return (
    <RolesContext.Provider
      value={{
        entities,
        loading,
        fetchEntities,
        fetchEntity,
        createNewEntity,
        editEntity,
        deleteEntity,
        fetchGroups,

        searchEntity,
        setSearchEntity,
        entitiesPerRow,
        setEntitiesPerRow,
        paginate,
        setPaginate,
      }}
    >
      {_params.children}
    </RolesContext.Provider>
  );
};

/*
Calls current context to be used in child component
*/
export function useRoles() {
  const context = useContext(RolesContext);

  if (!context) {
    throw new Error("useRoles must be used within an RolesProvider");
  }

  return context;
}

import { FC } from 'react';
import { Button } from '@mui/material';

import { IActionButton } from 'src/types';

const ActionButton: FC<IActionButton> = (params) => {
	return (
		<div className='flex'>
			<Button
				variant='contained'
				sx={{
					minWidth: 'fit-content',
					width: '250px',
					minHeight: 'fit-content',
					height: '48px',
					backgroundColor: params.color,
				}}
				onClick={params.onClick}
			>
				{params.text}
			</Button>
		</div>
	);
};

export default ActionButton;

import { FC, useState } from "react";
import { useAnalyses } from "src/hooks/analyses";

import { theme, ItemCard } from "src/components";
import {
  Table,
  TableBody,
  TablePagination,
  TableContainer,
  //   TableRow,
} from "@mui/material";
import { IAnalysesDatalogList } from "src/types";

const ItemList: FC<IAnalysesDatalogList> = () => {
  const { entities } = useAnalyses();

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <div
      className="container flex column"
      style={{ width: "35%", height: "93%" }}
    >
      <div className="container">
        <h1 className="text-18 primary">Eventos anteriores</h1>
      </div>

      <div className="container" style={{ height: "93%", marginTop: "2%" }}>
        {entities.length === 0 && (
          <hr
            className="m-0"
            style={{
              width: "100%",
              borderBottom: `1px solid ${theme.palette.backgroundElements.main}`,
              borderTop: "0",
            }}
          />
        )}
        <TableContainer sx={{ maxHeight: "90%" }}>
          <Table
            sx={{
              "& .MuiDataGrid-columnHeaders": { display: "none" },
              "& .MuiDataGrid-virtualScroller": {
                marginTop: "0 !important",
                height: "70vh !important",
              },
              "& .MuiDataGrid-virtualScrollerRenderZone": {
                width: "99.5%",
              },
              "& .MuiDataGrid-virtualScrollerContent": {
                height: "100% !important",
              },
            }}
          >
            <TableBody>
              {entities
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => (
                  //   <TableRow>
                  <ItemCard
                    key={row.id}
                    analysis={row}
                    itemIndex={entities.length - index}
                    order={index}
                  />
                  //   </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10]}
          component="div"
          count={entities.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </div>
    </div>
  );
};

export default ItemList;

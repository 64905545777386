import { FC, createContext, useContext, useState } from 'react';
import moment from 'moment';

import { AnalysisServices } from 'src/services/analyses';
import { ReportServices } from 'src/services/reports';
import {
	IAnalysis,
	IAnalysisContext,
	IAnalysisReportGenerate,
	IHookProvider,
	IReportFilterParams,
	IAnalyze,
} from 'src/types';

/*
Creates a new context using a given interface as type
*/
const AnalysesContext = createContext<IAnalysisContext>({} as IAnalysisContext);

/**
Applies state and CRUD functionalities for analysis objects,
providing them to child components
@provider
*/
export const AnalysesProvider: FC<IHookProvider> = (_params: IHookProvider) => {
	const analysisServices = new AnalysisServices();

	const [selectedAnalysisIndex, setSelectedAnalysisIndex] = useState<number>(0);
	const [entities, setAnalyses] = useState<IAnalysis[]>([]);
	const [loading, setLoading] = useState<boolean>(false);
	const [searchEntity, setSearchEntity] = useState<string>('');
	const [entitiesPerRow, setEntitiesPerRow] = useState<string>('8');
	const [paginate, setPaginate] = useState<number>(0);

	const createNewEntity = async (_analysis: IAnalysis) => {
		try {
			const analysis = await analysisServices.createEntity(_analysis);

			fetchEntities();
			return analysis;
		} catch (_err) {
			throw _err;
		}
	};

	const editEntity = async (_id: string, _analysis: IAnalysis) => {
		try {
			const analysis = await analysisServices.updateEntity(_id, _analysis);

			const temp_analyses = [...entities];
			for (let index = 0; index < temp_analyses.length; index++) {
				if (temp_analyses[index].id === _id) {
					temp_analyses[index] = analysis;
				}
			}
			setAnalyses([...temp_analyses]);

			return analysis;
		} catch (_err) {
			throw _err;
		}
	};

	const deleteEntity = async (_id: string) => {
		try {
			const analysis = await analysisServices.deleteEntity(_id);

			fetchEntities();
			return analysis;
		} catch (_err) {
			throw _err;
		}
	};

	const fetchAll = async (_query?: string) => {
		setLoading(true);
		try {
			const analyses: IAnalysis[] = await analysisServices.getAll(_query);
			setAnalyses([...analyses]);
		} catch (_err) {
			console.log(_err);
		} finally {
			setLoading(false);
		}
	};

	const fetchEntities = async (_query?: string) => {
		let analyses: IAnalysis[] = [];
		setLoading(true);
		try {
			analyses = await analysisServices.getEntities(_query);
			setAnalyses([...analyses]);
		} catch (_err) {
			console.log(_err);
		} finally {
			setLoading(false);
			return analyses;
		}
	};

	const fetchEntity = async (_id: string) => {
		const analysis: IAnalysis = await analysisServices.getEntity(_id);
		return analysis;
	};

	const clearAnalyses = () => {
		setAnalyses([]);
	};

	const generateReport = async (_payload: IAnalysisReportGenerate, _query?: string) => {
		const reportServices = new ReportServices();
		const report: any = await reportServices.generateReport(_payload, _query);

		if (report) {
			const url = window.URL.createObjectURL(new Blob([report]));
			const link = document.createElement('a');
			link.href = url;
			link.setAttribute(
				'download',
				'relatorio_' + moment(Date.now()).format('YYYY_MM_DD_HH_mm_ss') + '.pdf'
			); // or any other extension
			document.body.appendChild(link);
			link.click();
		}
	};

	const createQuery = (params: IReportFilterParams) => {
		let query: string = '';
		if (params.startDate)
			query += `&start_date=${moment(params.startDate?.toString()).format('YYYY-MM-DD')}`;
		if (params.endDate)
			query += `&end_date=${moment(params.endDate?.toString()).format('YYYY-MM-DD')}`;
		if (params.startTime)
			query += `&start_time=${moment(params.startTime?.toString()).format('HH:mm:ss')}`;
		if (params.endTime)
			query += `&end_time=${moment(params.endTime?.toString()).format('HH:mm:ss')}`;

		const deviceNames: string[] = params.deviceNames.filter((item) => item !== 'Todos');
		if (deviceNames.length > 0) query += `&device_names=${deviceNames.join(',')}`;
		if (params.analysisIsAiRight) query += `&analysis_is_ai_right=${params.analysisIsAiRight}`;

		if (params.orderByDesc) query += '&order_by_desc=1';
		if (params.orderByDateTime) query += '&order_by_datetime=1';
		if (params.orderById) query += '&order_by_id=1';
		if (params.orderByDetection) query += '&order_by_detection=1';
		if (params.orderByDeviceName) query += '&order_by_device_name=1';

		if (query.length > 0) query = '?' + query.slice(1);
		return query;
	};

	const analyze = async (_payload: any) => {
		let analysis = null;
		setLoading(true);
		try {
			analysis = await analysisServices.analyze(_payload);
		} catch (_err) {
			throw _err;
		} finally {
			setLoading(false);
			return analysis;
		}
	};

	return (
		<AnalysesContext.Provider
			value={{
				selectedAnalysisIndex,
				setSelectedAnalysisIndex,
				entities,
				loading,
				fetchAll,
				fetchEntities,
				fetchEntity,
				createNewEntity,
				editEntity,
				deleteEntity,
				clearAnalyses,
				createQuery,
				generateReport,
				analyze,

				searchEntity,
				setSearchEntity,
				entitiesPerRow,
				setEntitiesPerRow,
				paginate,
				setPaginate,
			}}
		>
			{_params.children}
		</AnalysesContext.Provider>
	);
};

/*
Calls current context to be used in child component
*/
export function useAnalyses() {
	const context = useContext(AnalysesContext);

	if (!context) {
		throw new Error('useAnalyses must be used within an AnalysesProvider');
	}

	return context;
}

import { FC, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import api from 'src/services';
import Logout from './logout';
import { AppBar, Toolbar, Button } from '@mui/material';
import { useAnalyses } from 'src/hooks/analyses';
//import logo from 'src/assets/logo-porto.svg';
import logo from 'src/assets/logo_small.svg';

const pages = [
	// { name: 'Análise', path: 'analysis' },
	{ name: 'Análise', path: 'imageUpload' },
	// { name: 'Datalog', path: 'datalog' },
	// { name: 'Relatórios', path: 'reports' },
	// { name: 'Gerenciamento', path: 'management' },
];

/*
Renders the application bar with logo and title
*/
const AppHeader: FC = () => {
	const navigate = useNavigate();
	const analysesHook = useAnalyses();

	const navigateTo = (path: string) => {
		// if (path !== 'datalog') analysesHook.clearAnalyses();
		navigate(`/${path}`, { replace: true });
	};

	const checkAPIHealth = async () => {
		let response = null;
		try {
			response = await api.get('/');
		} catch {}

		if (response && response.request.status !== 403 && response.request.status > 399) {
			console.log('Servidor não está rodando');
		}
	};

	useEffect(() => {
		checkAPIHealth();
	}, []);

	return (
		<AppBar className='flex center-x app-header app-padding' position='sticky' color='default'>
			<Toolbar className='flex space-between' disableGutters>
				<div className='box-8 flex' style={{ gap: '64px' }}>
					<div style={{ height: '48px' }}>
						<img height='100%' src={logo} alt='Logo da Pix Force' />
					</div>

					<div className='flex' style={{ gap: '48px' }}>
						{pages.map((page) => (
							<Button
								key={page.name}
								disableRipple
								color='primary'
								sx={{
									padding: 0,
									textTransform: 'capitalize',
									fontSize: '14pt',
									fontWeight: 'bold',
									'&:hover': { backgroundColor: 'transparent' },
								}}
								onClick={() => navigateTo(page.path)}
							>
								{page.name}
							</Button>
						))}
					</div>
				</div>
				{/* <Logout /> */}
			</Toolbar>
		</AppBar>
	);
};

export default AppHeader;

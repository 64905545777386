import { FC, useState, useEffect } from 'react';
import { useAnalyses } from 'src/hooks/analyses';
import { API_URL } from 'src/services';

import DetailsDescription from './description';
import { theme, ImageZoomModalButton } from 'src/components';
import { IAnalysesDetailedInfoCard } from 'src/types';
import { NO_IMAGE } from 'src/utils/consts';

const ItemDetailed: FC<IAnalysesDetailedInfoCard> = () => {
	const { entities, selectedAnalysisIndex } = useAnalyses();

	const [img, setImg] = useState('');

	const getImage = async () => {
		const analysis = entities[selectedAnalysisIndex];

		if (analysis && analysis.images) {
			try {
				setImg(`http://${API_URL}/images/${analysis.images[0].id}/img`);
			} catch {
				setImg('');
			}
		}
	};

	useEffect(() => {
		getImage();
	}, [selectedAnalysisIndex]);

	return (
		<div className='container flex column' style={{ width: '60%', height: '93%' }}>
			<div className='container'>
				<h1 className='text-18 primary'>
					Evento{' '}
					{entities[selectedAnalysisIndex]
						? entities[selectedAnalysisIndex].id?.split('-')[1].toUpperCase()
						: 'não encontrado'}
				</h1>
			</div>

			<div className='container flex' style={{ height: '88%', marginTop: '1%' }}>
				<div
					className='flex center'
					style={{
						width: '75%',
						overflow: 'hidden',
						position: 'relative',
						border: `1px solid ${theme.palette.backgroundElements.main}`,
						borderRadius: '3px',
					}}
				>
					{img.length > 0 ? (
						<img
							height='100%'
							src={img}
							alt='Frame capturada da anomalia detectada'
							onError={(event) => (event.currentTarget.src = NO_IMAGE)}
						/>
					) : (
						<img height='100%' src={NO_IMAGE} alt='Imagem não encontrada' />
					)}

					{img.length > 0 && <ImageZoomModalButton image_path={img} />}
				</div>
				<DetailsDescription key={selectedAnalysisIndex} />
			</div>
		</div>
	);
};

export default ItemDetailed;

import { FC } from "react";
import { useAnalyses } from "src/hooks/analyses";

import { theme, IconTextDisplay } from "src/components";
import { Button } from "@mui/material";
import { IAnalysesDatalogItemCard } from "src/types";
import CalendarIcon from "@mui/icons-material/CalendarMonthOutlined";
import ScheduleOutlinedIcon from "@mui/icons-material/ScheduleOutlined";
import VideocamOutlinedIcon from "@mui/icons-material/VideocamOutlined";
import ThumbUpOutlinedIcon from "@mui/icons-material/ThumbUpOutlined";
import ThumbDownOutlinedIcon from "@mui/icons-material/ThumbDownOutlined";
import { parseDateToString } from "src/utils/dates";

const ItemCard: FC<IAnalysesDatalogItemCard> = (
  params: IAnalysesDatalogItemCard
) => {
  const { setSelectedAnalysisIndex } = useAnalyses();

  const dateTime: string[] = params.analysis
    ? parseDateToString(params.analysis.startedAt).split(" ")
    : ["-", "-.-"];

  const handleIndexSelection = () => {
    setSelectedAnalysisIndex(params.order);
  };

  return (
    <div
      className="flex space-between p-1"
      style={{
        width: "98%",
        marginBottom: "1.5%",
        backgroundColor: "#f5f5f5",
        border: `1px solid ${theme.palette.backgroundElements.main}`,
        borderRadius: "3px",
        minHeight: "40px",
        maxHeight: "fit-content",
      }}
    >
      <div className="flex px-1">
        <Button
          variant="text"
          sx={{
            minWidth: "32px",
            "&:hover": { backgroundColor: "transparent" },
          }}
          disableRipple
          onClick={handleIndexSelection}
        >
          <span className="flex center text-12" style={{ marginRight: "20%" }}>
            {params.itemIndex}
          </span>
          <span className="flex center text-12 black m-0">
            {params.analysis.id?.split("-")[1]}
          </span>
        </Button>
      </div>
      <div className="flex px-1">
        <IconTextDisplay
          icon={<CalendarIcon htmlColor={theme.palette.secondary.main} />}
          textContent={dateTime[0]}
        />
      </div>
      <div className="flex px-1">
        <IconTextDisplay
          icon={
            <ScheduleOutlinedIcon htmlColor={theme.palette.secondary.main} />
          }
          textContent={dateTime[1].split(".")[0]}
        />
      </div>
      <div className="flex inline px-1">
        <IconTextDisplay
          icon={
            params.analysis ? (
              <VideocamOutlinedIcon htmlColor={theme.palette.secondary.main} />
            ) : (
              <VideocamOutlinedIcon htmlColor={theme.palette.error.main} />
            )
          }
          textContent={
            params.analysis.images
              ? `${params.analysis.images[0].device.name}`
              : "Não identificado"
          }
        />
      </div>
      <div className="flex px-1">
        <div className="container flex space-between center-y">
          <ThumbUpOutlinedIcon
            style={{ minWidth: "32px", maxWidth: "48px" }}
            htmlColor={
              params.analysis.isAiRight === 1
                ? theme.palette.success.main
                : theme.palette.backgroundElements.dark
            }
          />
          <ThumbDownOutlinedIcon
            style={{ minWidth: "32px", maxWidth: "48px" }}
            htmlColor={
              params.analysis.isAiRight === 0
                ? theme.palette.error.main
                : theme.palette.backgroundElements.dark
            }
          />
        </div>
      </div>
    </div>
  );
};

export default ItemCard;

import { FC, useEffect, useState } from 'react';
import { useAuth } from 'src/hooks/auth';
import { useClients } from 'src/hooks/clients';
import { useOS } from 'src/hooks/ordersOfService';
import { useTags } from 'src/hooks/tags';
import { useImages } from 'src/hooks/images';
import { IClients, IOrders, IOrderImages, ITags, IImages, IImagesAnalyses } from 'src/types';
import { SingleSelectWithSearchBar, AnalysisInfo, AnalysisImagesCompare } from 'src/components';

const AnalysisPage: FC = () => {
	const authHook = useAuth();
	const clientsHook = useClients();
	const osHook = useOS();
	const tagsHook = useTags();
	const imagesHooks = useImages();

	const [selectedClient, setSelectedClient] = useState<IClients>();
	const [selectedOS, setSelectedOS] = useState<IOrders>();
	const [selectedTag, setSelectedTag] = useState<ITags>();
	const [tagImagesRelation, setTagImagesRelation] = useState<any[]>([]);
	const [imageAnalysis, setImageAnalysis] = useState<IImagesAnalyses[]>([]);

	const getClients = async () => {
		await clientsHook.fetchEntities();
	};

	const getClientOrders = async () => {
		if (selectedClient) await osHook.fetchEntities('?client_id=' + selectedClient.id);
	};

	const getOSTagsAndImages = async () => {
		let osImages: IOrderImages[] = [];
		const tempTags: ITags[] = [];
		const tempImages: IImages[] = [];
		const tempTagImagesRelation: any[] = [];

		if (selectedOS) osImages = await osHook.fetchImages('', selectedOS.id);

		for (let index = 0; index < osImages.length; index++) {
			const osImage = osImages[index];

			const found = tempTags.findIndex((tag) => tag.id === osImage.tag.id);
			if (found < 0) {
				tempTags.push(osImage.tag);
				tempTagImagesRelation.push({
					tagId: osImage.tag.id,
					imageId: osImage.image.id,
				});
			} else {
				const foundTagCurrentImage = tempTagImagesRelation.findIndex(
					(relation) => relation.tagId === tempTags[found].id
				);
				if (foundTagCurrentImage !== -1) {
					tempTagImagesRelation[foundTagCurrentImage].imageId = osImage.image.id;
				}
			}
			tempImages.push(osImage.image);
		}
		tempTags.sort((tagA, tagB) => parseInt(tagA.code) - parseInt(tagB.code));

		setTagImagesRelation(tempTagImagesRelation);
		tagsHook.setTags(tempTags);
		imagesHooks.setImages(tempImages);
	};

	const getImageAnalysis = async () => {
		let tempImageAnalysis: any[] = [];

		if (selectedTag) {
			const selectedTagImages = tagImagesRelation.filter(
				(relation) => relation.tagId === selectedTag.id
			);

			for (let index = 0; index < selectedTagImages.length; index++) {
				tempImageAnalysis.push(
					...(await imagesHooks.fetchImageAnalyses(
						`?os_id=${selectedOS?.id}&client_id=${selectedClient?.id}&tag_id=${selectedTag?.id}`,
						selectedTagImages[index].imageId
					))
				);
			}
			setImageAnalysis(tempImageAnalysis);
		}
	};

	const checkOSProgress = async () => {
		if (selectedOS) {
			const isFinished: boolean = await osHook.checkOSProgress(selectedOS.id);
			if (isFinished) {
				getClientOrders();
				const osIndex = osHook.entities.findIndex((os) => os.id === selectedOS.id);
				setSelectedOS(osHook.entities[osIndex]);
			}
		}
	};

	const setOSandClearTags = (selectedOS: IOrders) => {
		setSelectedOS(selectedOS);
		setSelectedTag(undefined);
	};

	useEffect(() => {
		getClients();
		setSelectedClient(clientsHook.entities[0]);
	}, [authHook.accessToken]);

	useEffect(() => {
		setSelectedTag(undefined);
		tagsHook.setTags([]);
		getClientOrders();
	}, [selectedClient]);

	useEffect(() => {
		getOSTagsAndImages();
		const interval = setInterval(async () => {
			checkOSProgress();
		}, 1000 * 60 * 5); // 5 minutes
		return () => clearInterval(interval);
	}, [selectedOS]);

	useEffect(() => {
		getImageAnalysis();
	}, [selectedTag]);

	return (
		<div className='app-page flex grow'>
			<div
				className='flex column center bg'
				style={{
					minWidth: '150px',
					width: '180px',
					gap: '32px',
					padding: '24px 16px 24px 32px',
				}}
			>
				<div className='container'>
					<h3 className='m-0 primary'>Cliente</h3>
					<SingleSelectWithSearchBar
						itens={clientsHook.entities}
						selectedItem={selectedClient}
						selectAction={setSelectedClient}
					/>
				</div>
				<div className='container'>
					<h3 className='m-0 primary'>Ordem de Serviço</h3>
					<SingleSelectWithSearchBar
						itens={osHook.entities}
						selectedItem={selectedOS}
						selectAction={setOSandClearTags}
					/>
				</div>
				<div className='container'>
					<h3 className='m-0 primary'>Etiqueta da OS</h3>
					<SingleSelectWithSearchBar
						itens={tagsHook.entities}
						selectedItem={selectedTag}
						selectAction={setSelectedTag}
					/>
				</div>
			</div>

			{selectedClient && selectedOS && selectedTag && imageAnalysis.length > 0 ? (
				<div className='flex grow column space-between of-hide' style={{ padding: '32px' }}>
					<AnalysisInfo
						selectedClient={selectedClient}
						selectedOS={selectedOS}
						selectedTag={selectedTag}
						imageAnalysis={imageAnalysis[0]}
					/>
					<AnalysisImagesCompare imageAnalysis={imageAnalysis[0]} />
				</div>
			) : (
				<div className='flex grow center app-padding'>
					<h2 className='text-20 text-center primary'>
						Selecione cliente, ordem de serviço
						<br /> e etiqueta para poder visualizar a análise
					</h2>
				</div>
			)}
		</div>
	);
};

export default AnalysisPage;

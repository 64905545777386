import { AxiosResponse } from "axios";
import api from ".";
import { EntityServices } from "./entities";
import { IAnalysis, IAnalyze } from "src/types";

export class AnalysisServices extends EntityServices<IAnalysis> {

    constructor () {
        const _baseUrl: string = "/analyses";
        super("Análise", _baseUrl)
    }

    async analyze (_payload: any): Promise<IAnalyze> {
        const response: AxiosResponse = await api.post(`${this.baseUrl}`, _payload, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });

        if (response.data && response?.request?.status === 200) {
            let entity: IAnalyze = response.data
            
            if (!entity.result) throw new Error(`Erro ao criar um(a) ${this.entityForLog}.`);
            return entity;

        } else if (response.data && response?.request?.status === 400) {
            throw new Error(response.data as string);
        }
        throw new Error(`Não foi possível criar um(a) ${this.entityForLog}.`);
    }

}

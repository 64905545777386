import { FC, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const HomePage: FC = () => {
	const navigate = useNavigate();

	useEffect(() => {
		navigate('/imageUpload', { replace: true });
	}, []);

	return <div />;
};

export default HomePage;

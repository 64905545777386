import { FC, useState } from "react";
import { useAnalyses } from "src/hooks/analyses";
import { useComments } from "src/hooks/comments";
import { useUsers } from "src/hooks/users";

import { theme, IconTextDisplay, ConfirmDetectionAction } from "src/components";
import { IAnalysesDetailedInfoCard } from "src/types";
import CalendarIcon from "@mui/icons-material/CalendarMonthOutlined";
import ScheduleOutlinedIcon from "@mui/icons-material/ScheduleOutlined";
import VideocamOutlinedIcon from "@mui/icons-material/VideocamOutlined";
import ErrorOutlineOutlinedIcon from "@mui/icons-material/ErrorOutlineOutlined";
import { parseDateToString } from "src/utils/dates";

const DetailsDescription: FC<IAnalysesDetailedInfoCard> = () => {
  const { entities, selectedAnalysisIndex } = useAnalyses();
  const commentsHook = useComments();
  const usersHook = useUsers();
  const [comments, setComments] = useState("");

  const dateTime: string[] = entities[selectedAnalysisIndex]
    ? parseDateToString(entities[selectedAnalysisIndex].startedAt).split(" ")
    : ["-", "-.-"];

  const updateComments = async () => {
    const analysis = entities[selectedAnalysisIndex];

    if (analysis.id) {
      if (analysis.comments.length > 0) {
        analysis.comments[0].body = comments;
        if (analysis.comments[0].id) {
          await commentsHook.editEntity(
            analysis.comments[0].id,
            analysis.comments[0]
          );
        }
      } else {
        const users = await usersHook.fetchEntities();
        if (users[0].id) {
          const payload = {
            id: "",
            body: comments,
            analysisId: analysis.id,
            userId: users[0].id,
          };
          await commentsHook.createNewEntity(payload);
        }
      }
    }
    if (analysis.comments[0]) {
      setComments(analysis.comments[0].body);
    } else {
      setComments("");
    }
  };

  return (
    <div style={{ marginLeft: "2%" }}>
      <div className="container">
        <h2 className="text-16 normal m-0">Detalhes</h2>
      </div>
      {entities[selectedAnalysisIndex] ? (
        <div>
          <div className="my-10">
            <div className="flex center-y">
              <p className="text-14 bold secondary my-0 mx-1">ID</p>
              <p className="my-0 mx-5">
                {entities[selectedAnalysisIndex].id
                  ?.split("-")[1]
                  .toUpperCase()}
              </p>
            </div>
          </div>
          <div className="my-10">
            <IconTextDisplay
              icon={<CalendarIcon htmlColor={theme.palette.secondary.main} />}
              textContent={dateTime[0]}
            />
          </div>
          <div className="my-10">
            <IconTextDisplay
              icon={
                <ScheduleOutlinedIcon
                  htmlColor={theme.palette.secondary.main}
                />
              }
              textContent={dateTime[1].split(".")[0]}
            />
          </div>
          <div className="my-10">
            <IconTextDisplay
              icon={
                entities[selectedAnalysisIndex] ? (
                  <VideocamOutlinedIcon
                    htmlColor={theme.palette.secondary.main}
                  />
                ) : (
                  <VideocamOutlinedIcon htmlColor={theme.palette.error.main} />
                )
              }
              textContent={
                entities[selectedAnalysisIndex].images
                  ? `${entities[selectedAnalysisIndex].images[0].device.name}`
                  : "Não identificado"
              }
            />
          </div>
          <div className="my-10">
            <div className="my-2">
              <p className="text-14 m-0">Observação</p>
            </div>
            <div className="container">
              <form onBlur={() => updateComments()}>
                <label htmlFor="comments" hidden></label>
                <textarea
                  name="comments"
                  placeholder={
                    entities[selectedAnalysisIndex].comments[0]
                      ? entities[selectedAnalysisIndex].comments[0].body
                      : "Descreva uma observação para o evento"
                  }
                  style={{
                    font: "inherit",
                    padding: "2%",
                    minWidth: "150px",
                    maxWidth: "200px",
                    minHeight: "200px",
                    maxHeight: "33vh",
                    border: `solid 1px ${theme.palette.backgroundElements.main}`,
                    borderRadius: "3px",
                  }}
                  value={comments}
                  onChange={(event) => setComments(event.target.value)}
                />
              </form>
            </div>
          </div>
          <div className="my-10">
            <div className="my-2">
              <p className="text-14 m-0">Detecção está correta?</p>
            </div>
            <div className="flex px-1">
              <ConfirmDetectionAction key={selectedAnalysisIndex} />
            </div>
          </div>
        </div>
      ) : (
        <div>
          <IconTextDisplay
            icon={
              <ErrorOutlineOutlinedIcon htmlColor={theme.palette.error.main} />
            }
            textContent="Evento não encontrado"
          />
        </div>
      )}
    </div>
  );
};

export default DetailsDescription;

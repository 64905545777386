import { FC, createContext, useContext, useState } from "react";
import { CommentsServices } from "src/services/comments";
import { IComments, ICommentsContext, IHookProvider } from "src/types";

/*
Creates a new context using a given interface as type
*/
const CommentsContext = createContext<ICommentsContext>({} as ICommentsContext);

/**
Applies state and CRUD functionalities for comment objects,
providing them to child components
@provider
*/
export const CommentsProvider: FC<IHookProvider> = (_params: IHookProvider) => {
  const commentServices = new CommentsServices();

  const [entities, setComments] = useState<IComments[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [searchEntity, setSearchEntity] = useState<string>("");
  const [entitiesPerRow, setEntitiesPerRow] = useState<string>("8");
  const [paginate, setPaginate] = useState<number>(0);

  const createNewEntity = async (_comment: IComments) => {
    try {
      const comment = await commentServices.createEntity(_comment);

      //   fetchEntities();
      return comment;
    } catch (_err) {
      throw _err;
    }
  };

  const editEntity = async (_id: string, _comment: IComments) => {
    try {
      const comment = await commentServices.updateEntity(_id, _comment);

      //   fetchEntities();
      return comment;
    } catch (_err) {
      throw _err;
    }
  };

  const deleteEntity = async (_id: string) => {
    try {
      const comment = await commentServices.deleteEntity(_id);

      //   fetchEntities();
      return comment;
    } catch (_err) {
      throw _err;
    }
  };

  const fetchEntities = async (_query?: string) => {
    let comments: IComments[] = [];
    setLoading(true);
    try {
      comments = await commentServices.getEntities(_query);
      setComments([...comments]);
    } catch (_err) {
      console.log(_err);
    } finally {
      setLoading(false);
      return comments;
    }
  };

  const fetchEntity = async (_id: string) => {
    const comment: IComments = await commentServices.getEntity(_id);
    return comment;
  };

  return (
    <CommentsContext.Provider
      value={{
        entities,
        loading,
        fetchEntities,
        fetchEntity,
        createNewEntity,
        editEntity,
        deleteEntity,

        searchEntity,
        setSearchEntity,
        entitiesPerRow,
        setEntitiesPerRow,
        paginate,
        setPaginate,
      }}
    >
      {_params.children}
    </CommentsContext.Provider>
  );
};

/*
Calls current context to be used in child component
*/
export function useComments() {
  const context = useContext(CommentsContext);

  if (!context) {
    throw new Error("useComments must be used within an CommentsProvider");
  }

  return context;
}
